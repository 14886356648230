import { createStyles, MantineTheme } from "@mantine/styles";

export const useHeaderStyles = createStyles((theme: MantineTheme) => ({
  drawer: {
    position: "relative",
  },
  group: {
    gap: "46px !important",
    [theme.fn.smallerThan("md")]: {
      gap: "24px !important",
    },
  },
  anchor: {
    position: "relative",
    fontSize: theme.fontSizes.sm,

    // [theme.fn.smallerThan("md")]: {
    //   gap: "36px !important",
    //   border: "1px solid blue !important",
    // },
  },

  active: {
    color: "red",
    fontWeight: 500,
    "&::after": {
      content: '""',
      position: "absolute",
      top: 28,
      left: "50%",
      width: "24px",
      height: "2px",
      backgroundColor: "#06F !important",
      transition: "width 0.3s ease",
      transform: "translateX(-50%)",
      zIndex: 100,
    },
    [`@media (max-width: 768px)`]: {
      "&::after": {
        left: "4%",
      },
    },
  },

  inactive: {
    color: "#C0C0C0 !important",
    fontWeight: 400,
  },

  mobileButton: {
    [theme.fn.largerThan("xs")]: {
      width: "60%",
    },
  },

  hiddenMobile: {
    [theme.fn.smallerThan("lg")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("lg")]: {
      display: "none",
    },
  },
}));
