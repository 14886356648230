import { anta } from "@/app/fonts";
import { Button, ButtonProps } from "@mantine/core";
import { createStyles, MantineTheme } from "@mantine/styles";

interface CoreButtonProps
  extends Pick<
    ButtonProps,
    "loading" | "disabled" | "rightSection" | "fullWidth"
  > {
  title: string;
  variant: string;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
}

const useStyles = createStyles(
  (theme: MantineTheme, { variant }: { variant?: string }) => ({
    root: {
      fontSize: "16px !important",
      textTransform: "uppercase",
      borderRadius: "46px !important",
      [`@media (max-width: 768px)`]: {
        fontSize: "14px !important",
      },
      ...(variant === "primary" && {
        "&:hover": {
          backgroundColor: "#005be5 !important",
        },
      }),
      ...(variant === "underline" && {
        color: "white !important",
        textDecoration: "underline !important",
        textUnderlineOffset: "4px",
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      }),
    },
  })
);

const CoreButton = ({
  title,
  variant,
  type = "button",
  onClick,
  rightSection,
  fullWidth,
  ...props
}: CoreButtonProps) => {
  const { classes } = useStyles({ variant });

  return (
    <Button
      h={{ md: 52, base: 46 }}
      color="brand.0"
      variant={variant === "underline" ? "subtle" : variant}
      fw={500}
      onClick={onClick}
      type={type}
      fullWidth={fullWidth}
      rightSection={rightSection}
      className={`${anta.className} ${classes.root}`}
      {...props}
    >
      {title}
    </Button>
  );
};

export default CoreButton;
