import { createTheme } from "@mantine/core";

export const MantineProviderConfig = createTheme({
  colors: {
    brand: [
      "#0066FF", //primary
      "#FFFFFF", //title text color
      "#ADADAD", //secondary text color
      "#C8C8C8", //secondary text color 2
      "#0066FF",
      "#FFFFFF",
      "#0066FF",
      "#0066FF",
      "#0066FF",
      "#0066FF",
    ],
    background: [
      "#161518", //background
      "#202021", //nav background
      "#1A191B", //cards background
      "#FFFFFF",
      "#001B42", //border color 1
      "#2B2B2B", // border color 2
      "#161518",
      "#161518",
      "#161518",
      "#161518",
    ],
  },
  primaryColor: "brand",
});
