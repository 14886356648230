import React from 'react'

const VintIcon = () => {
    return (
        <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8628 0H0V22.4766H12.8628V0Z" fill="#204BE5" />
            <path d="M39.9995 0H27.1367V22.4766H39.9995V0Z" fill="#204BE5" />
            <path d="M27.1375 22.4767H12.8633V31.3981H27.1375V22.4767Z" fill="#204BE5" />
        </svg>
    )
}

export default VintIcon