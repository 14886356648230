import React from 'react'

const VintLogo = () => {
    return (
        <svg width="142" height="50" viewBox="0 0 142 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.4835 0H0V35.7931H20.4835V0Z" fill="#204BE5" />
            <path d="M63.6983 0H43.2148V35.7931H63.6983V0Z" fill="#204BE5" />
            <path d="M43.2145 35.7931H20.4834V50.0001H43.2145V35.7931Z" fill="#204BE5" />
            <path d="M93.2214 13.776L87.0297 30.1034L85.3758 34.4291H78.6327L76.9788 30.1034L70.7871 13.776H75.4097L81.6014 30.1034H82.4071L88.6412 13.776H93.2214Z" fill="#F5F5F5" />
            <path d="M101.547 6.05762H97.2217V10.3833H101.547V6.05762Z" fill="#F5F5F5" />
            <path d="M101.547 13.776H97.2217V34.4291H101.547V13.776Z" fill="#F5F5F5" />
            <path d="M124.588 13.776V34.4291H120.305V18.1017H109.83V34.4291H105.547V13.776H124.588Z" fill="#F5F5F5" />
            <path d="M141.99 13.776V18.1017H135.629V34.4291H131.303V18.1017H128.589V13.776H131.303V6.05762H135.629V13.776H141.99Z" fill="#F5F5F5" />
            <path d="M76.4639 39.7862C76.4639 39.5318 76.5063 39.3197 76.6335 39.1077C76.7607 38.8956 76.9304 38.726 77.1424 38.6412C77.3545 38.514 77.5665 38.4716 77.8209 38.4716H80.6623V39.2773H77.8209C77.6513 39.2773 77.5241 39.3197 77.4393 39.447C77.312 39.5318 77.2696 39.7014 77.2696 39.8286V40.2103C77.2696 40.3376 77.312 40.4648 77.4393 40.5496C77.5665 40.6344 77.6937 40.6768 77.8209 40.6768H79.5597C79.8142 40.6768 80.0262 40.7192 80.2383 40.8465C80.4503 40.9737 80.6199 41.1433 80.7472 41.313C80.8744 41.4826 80.9168 41.737 80.9168 41.9915V42.5852C80.9168 42.8397 80.8744 43.0517 80.7472 43.2638C80.6199 43.4758 80.4503 43.6454 80.2383 43.7303C80.0262 43.8575 79.8142 43.8999 79.5597 43.8999H76.4639V43.0941H79.6021C79.7718 43.0941 79.899 43.0517 79.9838 42.9245C80.111 42.8397 80.1534 42.7124 80.1534 42.5428V41.9491C80.1534 41.8219 80.111 41.6946 79.9838 41.5674C79.8566 41.4826 79.7293 41.3978 79.6021 41.3978H77.8634C77.6089 41.3978 77.3969 41.3554 77.1848 41.2281C76.9728 41.1009 76.8031 40.9313 76.6759 40.7616C76.5487 40.592 76.5063 40.3375 76.5063 40.0831V39.7862H76.4639Z" fill="#F5F5F5" />
            <path d="M84.917 39.9772C84.917 39.6803 85.0018 39.4258 85.129 39.2138C85.2987 38.9593 85.4683 38.7897 85.7228 38.6625C85.9772 38.5353 86.2317 38.4504 86.5285 38.4504H87.8008C88.0977 38.4504 88.3945 38.5353 88.649 38.6625C88.9034 38.7897 89.1155 39.0018 89.2427 39.2138C89.3699 39.4683 89.4547 39.7227 89.4547 39.9772V42.3945C89.4547 42.6913 89.3699 42.9458 89.2427 43.1578C89.1155 43.3699 88.9034 43.5819 88.649 43.7091C88.3945 43.8364 88.1401 43.9212 87.8008 43.9212H86.5285C86.2317 43.9212 85.9348 43.8364 85.7228 43.7091C85.4683 43.5819 85.2563 43.3699 85.129 43.1578C84.9594 42.9034 84.917 42.6489 84.917 42.3945V39.9772ZM85.7228 42.3945C85.7228 42.6065 85.8076 42.7761 85.9772 42.9034C86.1468 43.0306 86.3165 43.1154 86.5709 43.1154H87.8432C88.0552 43.1154 88.2673 43.0306 88.4369 42.9034C88.6066 42.7761 88.6914 42.6065 88.6914 42.3945V39.9772C88.6914 39.7651 88.6066 39.5955 88.4369 39.4682C88.2673 39.341 88.0977 39.2562 87.8432 39.2562H86.5709C86.3589 39.2562 86.1468 39.341 85.9772 39.4682C85.8076 39.5955 85.7228 39.7651 85.7228 39.9772V42.3945Z" fill="#F5F5F5" />
            <path d="M93.4541 38.4292H94.2599V42.4156C94.2599 42.6277 94.3447 42.7973 94.4719 42.9245C94.5991 43.0518 94.7688 43.1366 94.9808 43.1366H97.695V43.9424H94.9808C94.684 43.9424 94.4295 43.8575 94.2175 43.7303C93.963 43.6031 93.7934 43.391 93.6661 43.179C93.5389 42.9245 93.4541 42.6701 93.4541 42.4156V38.4292Z" fill="#F5F5F5" />
            <path d="M101.695 38.4292H102.501V42.4156C102.501 42.6277 102.586 42.7973 102.713 42.9245C102.84 43.0518 103.052 43.1366 103.264 43.1366H104.622C104.834 43.1366 105.003 43.0518 105.173 42.9245C105.342 42.7973 105.385 42.6277 105.385 42.4156V38.4292H106.191V42.4156C106.191 42.7125 106.106 42.9669 105.979 43.179C105.851 43.4334 105.639 43.6031 105.427 43.7303C105.173 43.8575 104.918 43.9424 104.622 43.9424H103.264C102.968 43.9424 102.713 43.8575 102.459 43.7303C102.204 43.6031 102.035 43.391 101.907 43.179C101.78 42.9245 101.695 42.6701 101.695 42.4156V38.4292Z" fill="#F5F5F5" />
            <path d="M110.19 38.4292H114.686V39.235H112.862V43.9424H112.056V39.235H110.233V38.4292H110.19Z" fill="#F5F5F5" />
            <path d="M118.687 38.4292H119.492V43.9424H118.687V38.4292Z" fill="#F5F5F5" />
            <path d="M123.492 39.9772C123.492 39.6803 123.577 39.4258 123.704 39.2138C123.874 38.9593 124.044 38.7897 124.298 38.6625C124.552 38.5353 124.807 38.4504 125.104 38.4504H126.376C126.673 38.4504 126.97 38.5353 127.224 38.6625C127.479 38.7897 127.691 39.0018 127.818 39.2138C127.945 39.4683 128.03 39.7227 128.03 39.9772V42.3945C128.03 42.6913 127.945 42.9458 127.818 43.1578C127.691 43.3699 127.479 43.5819 127.224 43.7091C126.97 43.8364 126.715 43.9212 126.376 43.9212H125.104C124.807 43.9212 124.51 43.8364 124.298 43.7091C124.044 43.5819 123.831 43.3699 123.704 43.1578C123.535 42.9034 123.492 42.6489 123.492 42.3945V39.9772ZM124.298 42.3945C124.298 42.6065 124.383 42.7761 124.552 42.9034C124.722 43.0306 124.892 43.1154 125.146 43.1154H126.418C126.63 43.1154 126.842 43.0306 127.012 42.9034C127.182 42.7761 127.267 42.6065 127.267 42.3945V39.9772C127.267 39.7651 127.182 39.5955 127.012 39.4682C126.842 39.341 126.673 39.2562 126.418 39.2562H125.146C124.934 39.2562 124.722 39.341 124.552 39.4682C124.383 39.5955 124.298 39.7651 124.298 39.9772V42.3945Z" fill="#F5F5F5" />
            <path d="M132.029 38.4292H133.09L135.507 42.7549V38.4292H136.313V43.9424H135.252L132.835 39.6591V43.9424H132.029V38.4292Z" fill="#F5F5F5" />
        </svg>
    )
}

export default VintLogo