"use client";
import "@mantine/core/styles.css";
import "./globals.css";
import "@mantine/notifications/styles.css";
import { ColorSchemeScript, MantineProvider } from "@mantine/core";
import { MantineProviderConfig } from "@/config/MantineProvider";
import { Notifications } from "@mantine/notifications";
import Navbar from "@/components/layout/navbar/Navbar";
import { useState, useEffect, ReactNode } from "react";
import { plusJakarta } from "@/app/fonts";
import Loading from "./loading";
import Script from "next/script";

interface RootLayoutProps {
  children: ReactNode;
}

export default function RootLayout({ children }: RootLayoutProps) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => {
      clearTimeout(loaderTimeout);
    };
  }, []);

  return (
    <html lang="en">
      <head>
        <title>Vint Solution - Complete Tech-Solution Company</title>
        <meta
          name="description"
          content="Vint Solution offers top-notch solutions for your business needs, specializing in web and mobile app development."
        />
        <meta
          name="keywords"
          content="Vint Solution, web development, mobile app development, business solutions"
        />
        <meta name="author" content="Vint Solution" />
        <link rel="canonical" href="https://www.vintsolution.com/" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <ColorSchemeScript defaultColorScheme="light" />

        {/* Open Graph */}
        <meta
          property="og:title"
          content="Vint Solution - Complete Tech-Solution Company"
        />
        <meta
          property="og:description"
          content="Empowering Your Brands Through Innovative Digital Solutions"
        />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/z1vo7it2h/VintSolution/vint-logo.png?tr=f-webp"
        />
        <meta property="og:url" content="https://www.vintsolution.com/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Vint Solution - Complete Tech-Solution Company"
        />
        <meta
          name="twitter:description"
          content="Empowering Your Brands Through Innovative Digital Solutions"
        />
        <meta
          name="twitter:image"
          content="https://ik.imagekit.io/z1vo7it2h/VintSolution/vint-logo.png?tr=f-webp"
        />
      </head>
      <body className={plusJakarta.className}>
        <Script id="clarity-script" strategy="afterInteractive">
          {`
          (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${process.env.PUBLIC_CLARITY_PROJECT_ID}");
          `}
        </Script>
        <MantineProvider theme={MantineProviderConfig}>
          {isLoading && <Loading />}
          <Notifications />
          <Navbar />
          {children}
        </MantineProvider>
      </body>
    </html>
  );
}
