import { Anchor, Burger, Drawer, Flex, Group } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import NavItems from "./NavItems ";
import { useHeaderStyles } from "./style";
import { useState } from "react";
import CoreButton from "@/components/core-components/core-button/CoreButton";
import VintLogo from "../../../../public/assets/svgs/VintLogo";
import VintIcon from "../../../../public/assets/svgs/VintIcon";

const Navbar = () => {
  const [burgerOpened, { toggle: toggleBurger }] = useDisclosure(false);
  const [drawerOpened, { toggle: toggleDrawer }] = useDisclosure(false);
  const [active, setActive] = useState("about-us");
  const { classes } = useHeaderStyles();
  const isTabScreen = useMediaQuery("(max-width: 768px)");

  return (
    <Flex
      justify="space-between"
      align="center"
      bg="background.1"
      c="#C0C0C0"
      style={{ position: "sticky", top: 0, zIndex: 110 }}
      px={{ md: 80, xs: 24, base: 16 }}
      py={{ sm: 24, base: 16 }}
    >
      {isTabScreen ? <VintIcon /> : <VintLogo />}

      {!isTabScreen && (
        <Group className={classes.group} align="center">
          <NavItems active={active} setActive={setActive} />
        </Group>
      )}

      {!isTabScreen && (
        <Anchor href="#contact">
          <CoreButton title="Hire Us" variant="primary" />
        </Anchor>
      )}

      {isTabScreen && (
        <Burger
          size="sm"
          opened={burgerOpened}
          onClick={() => {
            toggleBurger();
            toggleDrawer();
          }}
          aria-label="Toggle navigation"
          color="brand.0"
          style={{
            border: "1px solid ${theme.colors.brand[0]}",
            borderRadius: "4px",
          }}
        />
      )}

      <Drawer
        opened={drawerOpened}
        onClose={() => {
          toggleBurger();
          toggleDrawer();
        }}
        position="right"
        size="340px"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
        styles={{
          content: {
            backgroundColor: "#161518",
          },
          header: {
            backgroundColor: "#161518",
            color: "white",
          },
        }}
      >
        <Flex
          direction="column"
          gap={16}
          onClick={() => {
            toggleBurger();
            toggleDrawer();
          }}
        >
          <NavItems active={active} setActive={setActive} />
        </Flex>
      </Drawer>
    </Flex>
  );
};

export default Navbar;
