import { Anchor } from "@mantine/core";
import { useHeaderStyles } from "./style";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mantine/hooks";

interface LinkItem {
  link: string;
  label: string;
}

interface NavItemsProps {
  active: string;
  setActive: React.Dispatch<React.SetStateAction<string>>;
}

const links: LinkItem[] = [
  { link: "about-us", label: "About" },
  { link: "our-services", label: "Services" },
  { link: "portfolio", label: "Portfolio" },
  { link: "testomonials", label: "Testimonials" },
  { link: "contact", label: "Contact" },
];

const NavItems = ({ active, setActive }: NavItemsProps) => {
  const { classes, cx } = useHeaderStyles();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleScroll = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: string
  ) => {
    event.preventDefault();
    setActive(link);

    const section = document.getElementById(link);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: (index: number) => ({
      opacity: 1,
      x: 0,
      transition: { duration: 0.1, delay: index * 0.3 },
    }),
  };

  const items = links.map((link) => (
    <Anchor
      underline="never"
      key={link.label}
      href={`#${link.link}`}
      className={cx(classes.anchor, {
        [classes.active]: active === link.link,
        [classes.inactive]: active !== link.link,
      })}
      onClick={(event) => handleScroll(event, link.link)}
    >
      {link.label}
    </Anchor>
  ));

  const drawerItem = links.map((link, index) => (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={itemVariants}
      custom={index}
      key={index}
      className={cx(classes.drawer)}
    >
      <Anchor
        underline="never"
        key={link.label}
        href={`#${link.link}`}
        className={cx({
          [classes.active]: active === link.link,
          [classes.inactive]: active !== link.link,
        })}
        onClick={(event) => handleScroll(event, link.link)}
      >
        {link.label}
      </Anchor>
    </motion.div>
  ));

  return <>{isMobile ? drawerItem : items}</>;
};

export default NavItems;
